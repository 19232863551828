<template>
  <PlaceForm :edit="true" :initial-place="initialPlace" @submit="updatePlace" />
</template>
<script setup lang="ts">
import { mockPlace } from '~/bin/publisher';
import { type ExistingPlace, type Place } from '~/interfaces/publisher';
import { usePlacesStore } from '~/store/PlacesModule';

/* eslint-disable no-undef */
definePageMeta({
  layout: 'data',
});

useHead({
  title: 'Редактирование Telegram-канала TeleAds.',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content:
        'Укажите цену за размещение в вашем канале: пост, репост, сторис. Зарабатывайте в TeleAds!',
    },
  ],
});

const { setPlaces, placesMap } = usePlacesStore();

const {
  params: { id },
} = useRoute();
const initialPlace = ref<ExistingPlace | null>(null);
const havingInitialPlace = placesMap.has(+id);

onBeforeMount(async () => {
  if (havingInitialPlace) {
    initialPlace.value = placesMap.get(+id) as ExistingPlace;
  } else {
    setTimeout(() => {
      initialPlace.value = mockPlace;
    }, 1000);
  }
});

const updatePlace = async (place: Place) => {
  try {
    setPlaces([{ ...mockPlace, ...place, id: +id }]);
    navigateTo('/publisher');
  } catch (error) {
    console.log(error);
  }
};
</script>
