<template>
  <div class="col-gap-3">
    <label :for="name" class="text-zinc-600 text-xs -mb-1">{{ inputLabel }}</label>
    <component
      :is="inputComponent"
      :id="name"
      :value="inputValueComputed"
      :name="name"
      :type="type"
      :input-class-name="inputClassName"
      :placeholder="placeholder"
      :class="errorMessage !== true"
      class="flex-1"
      autocomplete="on"
      @input="$emit('onInput', $event.target.value)"
    />
    <div class="h-4 -mt-2">
      <transition name="opacity-absolute"
        ><span v-show="errorMessage !== true" class="text-danger mt-1 text-xs">{{
          errorMessage
        }}</span></transition
      >
    </div>
  </div>
</template>
<script setup lang="ts">
import type { VeeValidateError } from '~~/interfaces';
import centeredInputVue from './centered.vue';
// @ts-ignore
import inputSmallVue from './small.vue';
// @ts-ignore
import myInputVue from './index.vue';

type InputComponent = typeof inputSmallVue | typeof centeredInputVue | typeof myInputVue;

const InputComponents = new Map<any, InputComponent>([
  ['default', inputSmallVue],
  ['centered', centeredInputVue],
  ['big', myInputVue],
]);

interface Props {
  inputLabel: string;
  name: string;
  inputValue: string;
  componentType?: any;
  placeholder?: string | undefined;
  type?: string;
  errorMessage?: VeeValidateError;
  inputClassName?: string | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  errorMessage: undefined,
  inputClassName: undefined,
  componentType: 'default',
  type: 'text',
  placeholder: 'Введите ...',
});
defineEmits(['onInput']);
/* eslint-disable no-undef */
const inputValueComputed = computed(() => props.inputValue);

const inputComponent = InputComponents.get(props.componentType) as InputComponent;
</script>
