<template>
  <form class="col-gap-3" @submit.prevent="validateAndCreatePlace">
    <h1 class="text-center my-4">{{ props.edit ? 'Редактирование' : 'Добавление' }} канала</h1>
    <div class="container cols flex gap-40">
      <div class="col-gap-3">
        <UIInputAndLabel
          :input-value="placeName"
          input-label="Название"
          name="place-name"
          :error-message="errorName"
          @on-input="onSetName"
        />
        <UIInputAndLabel
          :input-value="placeUrl"
          input-label="Ссылка"
          name="place-url"
          :error-message="errorUrl"
          @on-input="onSetUrl"
        />
        <div class="col-gap-3">
          <label for="categories-select">Категория</label>
          <ClientOnly>
            <ElSelect
              id="categories-select"
              v-model="place.categories"
              multiple
              filterable
              :multiple-limit="MAX_PLACE_CATEGORIES_COUNT"
              placeholder="Выберите категории"
            >
              <ElOptionGroup
                v-for="group in categoriesWithGroups"
                :key="group.name"
                :label="group.name"
              >
                <ElOption
                  v-for="item in group.categories"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </ElOptionGroup>
            </ElSelect>
          </ClientOnly>
        </div>

        <div class="col-gap-3 mt-4">
          <label for="type">Тип канала</label>
          <ClientOnly>
            <ElRadioGroup id="type" v-model="place.private" class="flex-col">
              <ElRadio size="large" :value="false">Открытый канал</ElRadio>
              <ElRadio size="large" :value="true">Закрытый канал</ElRadio>
            </ElRadioGroup>
          </ClientOnly>
        </div>
        <div class="col-gap-3 mt-3">
          <label>Комментарий для рекламодателя</label>
          <textarea v-model="place.contact" rows="6" :placeholder="COMMENT_PLACEHOLDER" />
        </div>
        <ClientOnly v-if="edit">
          <PlaceFormStatus
            :status="place.status"
            :true-value-status="PlaceStatus.ARCHIVE"
            action="Скрыть"
            @toggle-status="onTogglePlaceStatus"
          />
          <PlaceFormStatus
            :status="place.status"
            :true-value-status="PlaceStatus.DELETED"
            action="Удалить"
            @toggle-status="onTogglePlaceStatus"
          />
        </ClientOnly>
      </div>
      <div class="col-gap-3">
        <div class="col-gap-3">
          <label>Стоимость</label>
          <ul class="flex flex-col gap-1">
            <PlacePrice
              v-for="{ name, value } in publicationFormats"
              :key="name"
              :publication-format-name="name"
              :publication-format-value="value"
              :cost="place.price[value]"
              :edit="edit"
              @set-price="place.price[value] = $event"
            />
          </ul>
        </div>
      </div>
    </div>
    <UIButtonPrimary class="mt-6 mx-auto mb-6">Сохранить</UIButtonPrimary>
  </form>
</template>

<script setup lang="ts">
import { ElSelect, ElOptionGroup, ElOption, ElRadio, ElRadioGroup, ElMessage } from 'element-plus';
import { categoriesWithGroups, publicationFormats } from '~/bin/publisher/place/new';
import useTemplateField from '~/composables/UI/useTemplateField';
import { validateRequiredField, validateTelegramUrl } from '~/helpers/validators/field-validators';
import {
  PublicationFormat,
  type Place,
  type ExistingPlace,
  PlaceStatus,
} from '~/interfaces/publisher';
import { transferValues } from '~/common/functions';
import { COMMENT_PLACEHOLDER } from '~/common/constants';
import { errorMessage } from '~/common/feedback';
/* eslint-disable no-undef */

interface Props {
  edit?: boolean | undefined;
  initialPlace?: ExistingPlace | undefined | null;
}
const props = withDefaults(defineProps<Props>(), {
  edit: false,
  initialPlace: undefined,
});
const emits = defineEmits(['submit']);

const MAX_PLACE_CATEGORIES_COUNT = 3;

const place = reactive({
  name: '',
  url: '',
  categories: [],
  private: false,
  contact: '',
  status: PlaceStatus.ENABLED,
  price: {
    [PublicationFormat.post24h]: null,
    [PublicationFormat.post48h]: null,
    [PublicationFormat.post72h]: null,
    [PublicationFormat.fixedInTop]: null,
    [PublicationFormat.postWithoutDelete]: null,
    [PublicationFormat.story]: null,
    [PublicationFormat.repost]: null,
  },
});

const onTogglePlaceStatus = (status: PlaceStatus) => {
  if (place.status === status) {
    place.status = PlaceStatus.ENABLED;
  } else {
    place.status = status;
  }
};

const {
  value: placeName,
  errorMessage: errorName,
  setStateFunc: setPlaceName,
} = useTemplateField<string>(validateRequiredField, 'placeName', {
  initialValue: '',
});

const {
  value: placeUrl,
  errorMessage: errorUrl,
  setStateFunc: setPlaceUrl,
} = useTemplateField<string>(validateTelegramUrl, 'placeUrl', {
  initialValue: '',
});

const getPlaceErrors = () => {
  const errors: string[] = [];
  if (!place.name) {
    errors.push('Укажите название канала');
  }

  if (Object.values(place.price).every((price) => price === null)) {
    errors.push('Укажите хотя бы один вид стоимости публикации');
  }

  if (!place.url) {
    errors.push('Укажите ссылку на канал');
    return errors;
  }
  if (typeof unref(errorUrl) === 'string') {
    errors.push('Укажите корректную ссылку на канал');
  }

  return errors;
};

const validateAndCreatePlace = () => {
  const errors = getPlaceErrors();

  if (errors.length) {
    errorMessage(errors.join(' \n'));
    return;
  }

  emits('submit', place);
};

const onSetName = (value: string) => {
  place.name = value;
  setPlaceName(value);
};

const onSetUrl = (value: string) => {
  place.url = value;
  setPlaceUrl(value);
};

if (props.edit) {
  const unwatch = watch(
    computed(() => props.initialPlace as Place),
    (newPlace: Place) => {
      transferValues(newPlace, place);
      setPlaceName(newPlace.name);
      setPlaceUrl(newPlace.url);
      unwatch();
    },
  );
}
</script>
<style scoped lang="scss">
.container {
  margin: auto;
}
</style>
<style lang="scss">
.cols {
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;
  }

  > div {
    max-width: 325px;
    width: 100%;
  }
}

.place-form-status {
  @apply mt-3 pl-4 py-2 bg-gray-200 rounded-lg;

  .el-checkbox {
    pointer-events: none;
  }
}

#__nuxt [for='categories-select'] + div > div {
  height: auto;
  min-height: clamp(38px, 2vw, 40px);

  input {
    min-height: auto;
    height: 5px;
  }
}
</style>
