<template>
  <li class="flex-center-between place-cost">
    <div class="flex-center-3">
      <ClientOnly>
        <ElSwitch
          :id="elSwitchId"
          v-model="status"
          :inactive-value="PlacePriceStatus.DISABLED"
          :active-value="PlacePriceStatus.ENABLED"
          style="--el-switch-on-color: #104870; --el-switch-off-color: #d5dce1"
          @change="changePriceActive"
        />
      </ClientOnly>
      <label :for="elSwitchId">{{ publicationFormatName }}</label>
    </div>
    <div @click="onClickPriceInput">
      <div class="relative">
        <input
          ref="inputRef"
          v-model="costValue"
          class="place-cost__input"
          :class="cost === null ? DISABLED_CLASS_NAME : ''"
          @input="setPrice($event as any)"
        />
        <Icon name="fa:rouble" :color="IconColor.GRAY" class="input-icon" />
      </div>
    </div>
  </li>
</template>
<script setup lang="ts">
import { ElSwitch } from 'element-plus';
import useFormattingField from '~/composables/publisher/useFormattingField';
import { PlacePriceStatus, IconColor } from '~/interfaces/publisher';

/* eslint-disable no-undef */

interface Props {
  publicationFormatName: string;
  publicationFormatValue: string;
  cost: number | null;
  edit: boolean | undefined;
}

const props = defineProps<Props>();
const emits = defineEmits(['setPrice']);

const status = ref(PlacePriceStatus.DISABLED);

const elSwitchId = `switch-${props.publicationFormatValue}`;
const inputRef = ref<HTMLInputElement>();

const DISABLED_CLASS_NAME = 'pointer-events-none opacity-40 grayscale';

const {
  field: costValue,
  onInputField: setPrice,
  getToData,
  getToView,
} = useFormattingField(
  computed(() => props.cost),
  inputRef,
  (value: number) => emits('setPrice', value),
  'price',
);

if (props.edit) {
  const unwatch = watch(
    computed(() => props.cost),
    (newValue: number | null) => {
      unwatch();
      if (!newValue) return;
      status.value = PlacePriceStatus.ENABLED;
      costValue.value = getToView((props.cost as number).toString());
    },
  );
}

const changePriceActive = () => {
  emits(
    'setPrice',
    unref(status) === PlacePriceStatus.DISABLED ? null : getToData(unref(costValue)),
  );
};

const onClickPriceInput = () => {
  if (unref(status) === PlacePriceStatus.ENABLED) return;
  status.value = PlacePriceStatus.ENABLED;
  changePriceActive();
  inputRef.value?.focus();
};
</script>
<style lang="scss" scoped>
.place-cost {
  @apply gap-5;

  &__input {
    max-width: 135px;
    padding-right: 32px;
  }
}
</style>
<style>
.el-switch {
  max-width: fit-content;
}
</style>
