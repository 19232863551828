import { type FieldOptions, type GenericValidateFunction, useField } from 'vee-validate';

export default function useTemplateField<T = string>(
  validateFunction: ((value: T) => string | true) | ((value: string) => string | true),
  key: string,
  options?: Partial<FieldOptions<any>>,
) {
  const { value, errorMessage, setState, resetField } = useField<T>(
    key,
    validateFunction as GenericValidateFunction<T>,
    options,
  );

  const setStateFunc = (value: T) => {
    setState({ value } as { value: T });
  };
  return {
    value,
    errorMessage,
    setStateFunc,
    resetField,
  };
}
