<template>
  <PlaceForm @submit="createPlace" />
</template>
<script setup lang="ts">
import { mockPlace } from '~/bin/publisher/index';
import type { Place } from '~/interfaces/publisher';
import { usePlacesStore } from '~/store/PlacesModule';

const { addPlace } = usePlacesStore();
/* eslint-disable no-undef */
definePageMeta({
  layout: 'data',
});

useHead({
  title: 'Добавить Telegram-канал в TeleAds.',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content:
        'Продавайте рекламу в вашем Telegram-канале через TeleAds! Добавляйте канал, обязательно укажите цены за размещения.',
    },
  ],
});

const createPlace = async (place: Place) => {
  try {
    addPlace({ ...mockPlace, ...place, id: Math.ceil(Math.random() * 1e5) });
    navigateTo('/publisher');
  } catch (error) {
    console.log(error);
  }
};
</script>
