<template>
  <div class="place-form-status flex-center-3" @click="$emit('toggleStatus', trueValueStatus)">
    <ElCheckbox v-model="checked" />{{ action }} канал
  </div>
</template>
<script setup lang="ts">
import { ElCheckbox } from 'element-plus';
import type { PlaceStatus } from '~/interfaces/publisher';

interface Props {
  status: PlaceStatus;
  trueValueStatus: PlaceStatus;
  action: string;
}

const props = defineProps<Props>();
defineEmits(['toggleStatus']);

const getIsChecked = () => props.status === props.trueValueStatus;

/* eslint-disable no-undef */
const checked = ref(getIsChecked());

watch(
  computed(() => props.status),
  () => {
    checked.value = getIsChecked();
  },
);
</script>
