<template>
  <input class="border-width-1" />
</template>
<style lang="scss" scoped>
input {
  @apply rounded-md border-gray-300 px-3 py-2 transition ease-in duration-300 focus:border-sky-400;

  &::placeholder {
    @apply text-xs;
  }
}
</style>
